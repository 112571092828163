import React, { useContext } from "react";
import "./index.css";
import { NavBar } from "../../../components/nav-bar";
import { IntroductionFrame } from "../../../frames/introduction-frame";
import { NavStatus } from "../../../components/nav-bar/nav-status";
import { navBarStore } from "../../../components/nav-bar/store/store";
import { Provider } from "react-redux";
import { ModalContext } from "../../../context/modal-context";
import { TargetsFrame } from "../../../frames/targets-frame";
import { GuaranteesFrame } from "../../../frames/guarantees-frame";
import { PriceFrame } from "../../../frames/price-frame";
import { ReviewFrame } from "../../../frames/review-frame";
import { FAQFrame } from "../../../frames/faq-frame";
import { FooterFrame } from "../../../frames/footer-frame";
import { EducationStagesFrame } from '../../../frames/education-stages-frame';
import { OtherCoursesFrame } from '../../../frames/other-courses-frame';
import { ContactsFrame } from '../../../frames/contacts-frame';
import { ScrollUpButton } from '../../../components/scroll-up-button';
import { EducationProcessFrame } from "../../../frames/education-process-frame";
import { BottomInfoZone } from '../../../components/bottom-info-zone';
import { TeachersFrame } from '../../../frames/teachers-frame';
import { ComposedProgramFrame } from '../../../frames/composed-program-frame';
import { getFileUrl } from '../../../api/url';
import { CourseContext } from '../../../context/course-context';
import { SignupModalWrapper } from '../../../modals/wrappers/signup-modal-wrapper';
import { AskQuestionModalWrapper } from '../../../modals/wrappers/ask-question-modal-wrapper';
import { DocumentModalWrapper } from '../../../modals/wrappers/document-modal-wrapper';
import { VideoModalWrapper } from '../../../modals/wrappers/video-modal-wrapper';
import { WorkModalWrapper } from '../../../modals/wrappers/work-modal-wrapper';
import { FeedbackModalWrapper } from '../../../modals/wrappers/feedback-modal-wrapper';
import { useScrollToSection } from "../../../hooks/use-scroll-to-section";
import { usePageTitle } from "../../../hooks/use-page-title";
import { AuthorFrame } from "../../../frames/author-frame";
import Shedile_block from "../../../components/Shedule_block/Shedule";
import Block_info from "../../../components/Block_info/Block_info";

const RefresherCourseOffline = () => {
  const {
    courseData
  } = useContext(CourseContext);

  const course = courseData?.course;

  usePageTitle(course, undefined);

  const modalContextValue = useContext(ModalContext);
  // console.log(course.id);

  useScrollToSection();
  let bgColor = '#6E27B6';
  if(course?.id== '673CD4B3'){
    bgColor='#000';
  }
  return course && (
    <div className="refresher-course">
      <NavStatus />

      <Provider store={navBarStore}>
        <NavBar
          className={`${course.type.toLowerCase()} ${course.format.toLowerCase()}`}
          navBar={course.navBar}
        />
      </Provider>
      {/* <Block_info type={'catalog'}/> */}
      <div
        className="refresher-course-bg--first"
        style={{ backgroundImage: `url(${getFileUrl(course.introductionFrame?.backgroundId ?? "")})`,
        backgroundColor: `${bgColor}`
      }}
      >
        <IntroductionFrame id="introduction-frame" />
      </div>
      
      {/*
      {course.id == 'b13aff85-76bf-406d-ba9e-9553bd31a9b9' ? <Shedile_block /> : null}
*/}

      <GuaranteesFrame id="guarantees-frame" />

      {/* <TargetsFrame id="targets-frame" /> */}

      {/* <EducationProcessFrame id="education-process-frame" /> */}

      <TeachersFrame id="teachers-frame" />

      <Shedile_block />

      <AuthorFrame id="teachers-frame" />

      <ComposedProgramFrame id="composed-program-frame" />

      <PriceFrame id="price-frame" />

      <ReviewFrame id="review-frame" />

      <EducationStagesFrame id='education-stages-frame' />

    

      <FAQFrame id="faq-frame" />

      <OtherCoursesFrame id='other-courses-frame' />

      <ContactsFrame id='contacts-frame' />

      <FooterFrame />

      <ScrollUpButton />

      <BottomInfoZone />

      <SignupModalWrapper ref={modalContextValue.signupModalWrapperRef} />
      <AskQuestionModalWrapper ref={modalContextValue.askQuestionModalWrapperRef} />
      <VideoModalWrapper ref={modalContextValue.videoModalWrapperRef} />
      <DocumentModalWrapper ref={modalContextValue.documentModalWrapperRef} />
      <FeedbackModalWrapper ref={modalContextValue.feedbackModalWrapperRef} />
      <WorkModalWrapper ref={modalContextValue.workModalWrapperRef} /> 
    </div>
  );
};

export default RefresherCourseOffline;
