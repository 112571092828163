import React, { useContext, useEffect, useMemo } from 'react';
import './index.css';
import { NavStatus } from '../../components/nav-bar/nav-status';
import { Provider } from 'react-redux';
import { navBarStore } from '../../components/nav-bar/store/store';
import { NavBar } from '../../components/nav-bar';
import { DirectionContext } from '../../context/direction-context';
import { InfoFrame } from '../../frames/direction/info-frame';
import { CourseAmountInfo, CoursesFrame } from '../../frames/direction/courses-frame';
import { FooterFrame } from '../../frames/footer-frame';
import { ScrollUpButton } from '../../components/scroll-up-button';
import { ContactsFrame } from '../../frames/contacts-frame';
import { AskQuestionModalWrapper } from '../../modals/wrappers/ask-question-modal-wrapper';
import { ModalContext } from '../../context/modal-context';
import { FilterButton } from '../../components/filter-button';
import { CourseFormat } from '../../api/interfaces/course/course-format';
import { CourseDto } from '../../api/interfaces/course/course-dto';
import { useCoursesFilter } from '../../frames/direction/courses-frame/filter/use-courses-filter';
import { FilterModalWrapper } from '../../modals/wrappers/filter-modal-wrapper';
import { removeElementClass } from '../../imperative-dom-control/toggle-element-class';
import { SignupModalWrapper } from "../../modals/wrappers/signup-modal-wrapper";
import { CategoryDto } from "../../api/interfaces/category/category-dto";
import { usePageTitle } from "../../hooks/use-page-title";
import { LocationModalWrapper } from "../../modals/wrappers/location-modal-wrapper";
import { getFileUrl } from "../../api/url";
import Block_action_april from '../../components/Block_action_april/Block_action_april';
import Block_freeze_price from '../../components/Block_freeze_price/Block_freeze_price';
import Block_info from '../../components/Block_info/Block_info';

export const CoursesCatalog: React.FC = () => {
  const modalContextValue = useContext(ModalContext);

  const {
    direction,
  } = useContext(DirectionContext);

  useEffect(() => {
    document.querySelector("link[rel~='icon']")?.setAttribute("href", getFileUrl(direction?.meta?.faviconImageId));
  }, [direction?.meta?.faviconImageId]);

  usePageTitle(undefined, direction);

  const courseAmountInfo: CourseAmountInfo = useMemo(() => {
    const formatAmountsMap = new Map<CourseFormat, CourseDto[]>();
    const categoriesAmountsMap = new Map<CategoryDto, CourseDto[]>();

    direction?.availableFormats.forEach(format => {
      formatAmountsMap.set(format, (direction?.courses ?? []).filter(course => course.format === format));
    });

    direction?.availableCategories.forEach(category => {
      categoriesAmountsMap.set(category, (direction?.courses ?? [] as CourseDto[]).filter(course => course.categories.map(category => category.type).includes(category.type)));
    });

    return {
      formatAmountsMap,
      categoriesAmountsMap
    };
  }, [
    direction
  ]);

  const coursesFilter = useCoursesFilter(courseAmountInfo, undefined, [], true);

  useEffect(() => {
    modalContextValue.changeFilterStateModal({
      coursesFilter
    });
  }, [coursesFilter, modalContextValue]);
  // console.log('direction');
  // console.log(direction?.id);
  // console.log(direction?.type);
  // console.log(direction);
  let arrFrize = {
    "bim": ['8 июля 2024', '#191760', '#D0FF14', 'https://bim.itmo.ru/catalog'],
    "cad": ['05 августа 2024', '#221802', '#FFCC33', 'https://cad.itmo.ru/catalog'],
    "code": ['4 июля 2024', '#191760', '#D0FF14', 'https://code.itmo.ru/catalog'],
    "3d": ['4 июля 2024', '#270A35', '#FFFF33', 'https://3d.itmo.ru/catalog'],
    "md": ['4 июля 2024', '#191760', '#D0FF14', 'https://md.itmo.ru/catalog'],
  };
  let dir = direction?.type;
  const typePage = 'catalog';
  // console.log(dir);
  // console.log(arrFrize);

  return direction && (
    <div className='courses-catalog'>
      <NavStatus />

      <Provider store={navBarStore}>
        <NavBar
          className={`catalog`}
          navBar={direction?.navBar}
        />
      </Provider>

      {/* <Block_info type={'catalog'}/> */}

      <div className='bg-brand-itmo-bluetiful border-bottom--offline-color'>
        <InfoFrame
          id='info-frame'
        />


        {/* <Block_freeze_price type={typePage} dir={dir} styleType={'catalog'} dateEnd={arrFrize["cad"][0]} backColor={arrFrize["cad"][1]} mainColor={arrFrize["cad"][2]} linkPage={arrFrize["cad"][3]} /> */}

        {/* {dir === 'bim' ? <div>{arrFrize[dir][0]}</div> : null}
        {dir === 'bim' ? <div>{arrFrize[dir][1]}</div> : null}
        {dir === 'bim' ? <div>{arrFrize[dir][2]}</div> : null}
        {dir === 'bim' ? <div>{arrFrize[dir][3]}</div> : null} */}

        {/* <Block_action_april type='catalog' /> */}
        

        <CoursesFrame
          id='courses-frame'
          courseAmountInfo={courseAmountInfo}
          coursesFilter={coursesFilter}
          allCatalog={false}
          specificEnabled={!!coursesFilter.selectedFormat || !!coursesFilter.selectedCategories.length}
        />

        {(!!coursesFilter.selectedFormat || !!coursesFilter.selectedCategories.length) && (
          <CoursesFrame
            id='courses-frame-all'
            courseAmountInfo={courseAmountInfo}
            coursesFilter={coursesFilter}
            allCatalog={true}
            specificEnabled={!!coursesFilter.selectedFormat || !!coursesFilter.selectedCategories.length}
          />
        )}
      </div>

      <ContactsFrame id='contacts-frame' />

      <FooterFrame />

      <ScrollUpButton />

      <FilterButton
        coursesFilter={coursesFilter}
        courseAmountInfo={courseAmountInfo}
      />

      <AskQuestionModalWrapper
        ref={modalContextValue.askQuestionModalWrapperRef}
      />

      <FilterModalWrapper
        applyClick={() => {
          removeElementClass(document.body, 'modal-open');
        }}
        courseAmountInfo={courseAmountInfo}
        lastSelectedFormat={coursesFilter.selectedFormat}
        lastSelectedCategories={coursesFilter.selectedCategories}
        setSelectedFormat={coursesFilter.setSelectedFormat}
        setSelectedCategories={coursesFilter.setSelectedCategories}
        ref={modalContextValue.filterModalWrapperRef}
      />

      <LocationModalWrapper ref={modalContextValue.locationModalWrapperRef} />

      <SignupModalWrapper
        ref={modalContextValue.signupModalWrapperRef}
      />
    </div>
  );
};
